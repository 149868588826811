





















































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules'

@Component
export default class Backup2FACodes extends Vue {
  @UserModule.Action getUser
  @UserModule.State currentUser

  showModal = false
  backupCodes: string[] = []

  async mounted() {
    this.$root.$on('show2FACodes', (backupCodes: string[]) => {
      this.showModal = true
      this.backupCodes = backupCodes
    })
  }

  downloadBackupCodes() {
    const element = document.createElement('a')
    const { username, email } = this.currentUser
    const fileTitle = `These are your OlympusGG backup codes for account ${username} (${email}). Keep them safe!`
    const backUpCodesStringified = this.backupCodes.map(e => `- ${e}\n`).join('')

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileTitle + '\n\n' + backUpCodesStringified))
    element.setAttribute('download', 'OlympusGGBackupCodes')

    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}
